




import { Component, Mixins } from 'vue-property-decorator'

import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component
export default class Commerce extends Mixins(PermissionsMixin) {
  private mounted () {
    if (this.$route.name === 'manager.commerce') {
      if (this.hasPermission(this.Permission.ORDERS_VIEW)) {
        this.$router.replace({ name: 'manager.commerce.orders' })
      } else if (this.hasPermission(this.Permission.ORDERS_PROMO_CREATE)) {
        this.$router.replace({ name: 'manager.commerce.promocodes' })
      } else if (this.hasPermission(this.Permission.ORDERS_LINK_LIST)) {
        this.$router.replace({ name: 'manager.commerce.links' })
      } else if (this.hasPermission(this.Permission.ORDERS_SALES_CREATE)) {
        this.$router.replace({ name: 'manager.commerce.discount' })
      } else {
        this.$router.replace({ name: 'manager' })
      }
    }
  }
}
